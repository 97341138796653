/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    h2: "h2",
    a: "a",
    div: "div",
    ul: "ul",
    li: "li",
    h3: "h3",
    pre: "pre",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Channel enable you to receive events from Signatu. When a certain event you are subscribed to, e.g., somebody consents to a a policy, an\nevent will be sent to your registered channel(s) for that event."), "\n", React.createElement(_components.p, null, "Depending on the type of channel the data may be JSON or a string. For example - Webhooks receive a ", React.createElement(_components.code, null, "HTTP POST"), " to the registered webhook url with event data. You can use the data to trigger actions in your systems - e.g., update your CRM data."), "\n", React.createElement(_components.h2, {
    id: "configuring-a-channel",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#configuring-a-channel",
    "aria-label": "configuring a channel permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Configuring a channel"), "\n", React.createElement(_components.p, null, "You can add and configure channels in your ", React.createElement(_components.a, {
    href: "/app/account#channels"
  }, "Signatu account"), " settings. Signatu\nhas a predefined channel for e-mail that you also can use to receive events to your e-mail address."), "\n", React.createElement(_components.p, null, "You need to subscribe a channel to one or more ", React.createElement(_components.a, {
    href: "events"
  }, "Event"), " to receive events of that type."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 742px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 67.5%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABVElEQVQoz5WSwW7bMAyG8/6nvsGeY9c+QrdLYgxNcmlsSKQsSpYriWRhy0E9rMHQ7yBQFD5IP8SDqoYQvPcA4L1PKQEAIrYtqxqA18slzrOoMjMixhiZWUQO5/P5dDoBADPXWkUk51zuTKp2mgdPvnDWhVJKMxf5eDx2XXe73YjIOYeI451lC0Dekx/92gEAtzLPs6ou8svvX8MwiEgppdZaduS11er9ETMv8voWFRH9PodQ5h+vzy5PqsqyhRERIsIVAGiP3NMuO1BOT91PzHFp6ef9MUZjjLUWER/KwsLTe4qRiOIdImqpHrHJOefz9XK5Xvu+n1ZSSiGEWivv+FpmZufcMAzW2nEcEdEYg3ecc+M4hhC+llW17/s/KzHGVnddZ4yJMf6r/Z1ZpA1WW6VWYZb/fd4ml1KstW1o31Oa+7cSqMktrTxgy9ymZ2NfP6ZN2AfPEC3uBYrcNQAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Channel topics\"\n        title=\"Channel topics\"\n        src=\"/static/3baa1cef264dc9e711bf77f3066a6dd9/14945/channel-topics.png\"\n        srcset=\"/static/3baa1cef264dc9e711bf77f3066a6dd9/f8f3a/channel-topics.png 200w,\n/static/3baa1cef264dc9e711bf77f3066a6dd9/6a8a8/channel-topics.png 400w,\n/static/3baa1cef264dc9e711bf77f3066a6dd9/14945/channel-topics.png 742w\"\n        sizes=\"(max-width: 742px) 100vw, 742px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.h2, {
    id: "supported-channels",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#supported-channels",
    "aria-label": "supported channels permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Supported Channels"), "\n", React.createElement(_components.p, null, "The supported channels are:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/api/v0/integration/channels#webhook"
  }, "Webhook"), ". You can also create webhooks using the ", React.createElement(_components.a, {
    href: "/docs/api/v0/integration/webhooks"
  }, "Webhook API"), "."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/api/v0/integration/channels#sqs"
  }, "Amazon Simple Queue Service (SQS)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs/api/v0/integration/channels#microsoft-azure-service-bus"
  }, "Microsoft Azure Service Bus")), "\n"), "\n", React.createElement(_components.p, null, "We also have a plugin for Google PubSub in development (alpha)."), "\n", React.createElement(_components.h3, {
    id: "webhook",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#webhook",
    "aria-label": "webhook permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Webhook"), "\n", React.createElement(_components.p, null, "Webhooks are HTTP endpoints that can accept HTTP POST requests with a JSON payload. Events will simply be sent\nto the registered endpoint."), "\n", React.createElement(_components.p, null, "| Field | Description |\n| ----- | ----------- |\n| ", React.createElement(_components.code, null, "url"), " | The fully qualified URL of the webhook that can accept HTTP POST requests. |\n| ", React.createElement(_components.code, null, "headers"), " | An optional headers field as JSON. You can e.g., set ", React.createElement(_components.code, null, "Authorization"), " headers here. |"), "\n", React.createElement(_components.h3, {
    id: "sqs",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sqs",
    "aria-label": "sqs permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "SQS"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/sqs/"
  }, "AWS Simple Queue Service"), " requires valid credentials that are allowed to send a message to the queue, and a valid URL to the queue:"), "\n", React.createElement(_components.p, null, "| Field | Description |\n| ----- | ----------- |\n| ", React.createElement(_components.code, null, "QueueUrl"), " | The full URL to the SQS queue |\n| ", React.createElement(_components.code, null, "accessKeyId"), " | The AWS IAM access key |\n| ", React.createElement(_components.code, null, "secretKeyId"), " | The AWS IAM secret access key |"), "\n", React.createElement(_components.p, null, "The provided IAM credentials must have permissions to send a message to the queue. We strongly recommend that you don’t give any\nadditional privileges for the access key. We suggest the following IAM policy (replace the Resource Arn with the Arn of your SQS queue):"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n    \"Version\": \"2012-10-17\",\n    \"Statement\": [\n        {\n            \"Effect\": \"Allow\",\n            \"Action\": \"sqs:SendMessage\",\n            \"Resource\": \"arn:aws:sqs:::YOUR_QUEUE\"\n        }\n    ]\n}\n")), "\n", React.createElement(_components.h3, {
    id: "microsoft-azure-service-bus",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#microsoft-azure-service-bus",
    "aria-label": "microsoft azure service bus permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Microsoft Azure Service Bus"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://azure.microsoft.com/services/service-bus/"
  }, "Microsoft Azure Service Bus"), " provides reliable messaging for the Azure platform."), "\n", React.createElement(_components.p, null, "Signatu needs permission to send a message to the queue. We suggest you set up a ", React.createElement(_components.strong, null, "Shared access policy"), " for the queue\nwith only ", React.createElement(_components.strong, null, "Send"), " permissions. Once the access policy is set up, you can use the ", React.createElement(_components.strong, null, "Primary Connection String"), " as the\n", React.createElement(_components.code, null, "connectionString"), " below."), "\n", React.createElement(_components.p, null, "| Field | Description |\n| ----- | ----------- |\n| ", React.createElement(_components.code, null, "queueName"), " | The name of the Service Bus Queue |\n| ", React.createElement(_components.code, null, "connectionString"), " | The ", React.createElement(_components.code, null, "connectionString"), ".  The associated access policy must have permission to send a message to the queue. |"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
